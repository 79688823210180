import packageDetails from './kewl/packageDetails';

const { iosUAKeyword } = packageDetails;
const ROOT = window;

export const UA = ROOT.navigator.userAgent;
const reg = new RegExp(`${iosUAKeyword}/(.+)`, 'i');
export const matchUA = UA.match(reg) || (window.LMUserAgent && window.LMUserAgent.match(reg));

export const IsAndroid = /Android|HTC/i.test(UA);
export const IsIPad = !IsAndroid && (/iPad/i.test(UA) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream;
export const IsIPhone = !IsAndroid && /iPod|iPhone/i.test(UA);
export const IsIOS = IsIPad || IsIPhone;
export const IsPC = !IsAndroid && !IsIOS && (UA.toLocaleLowerCase().indexOf('xiaomi') == -1);

export const IsNewIosApp = typeof ROOT.webkit === 'object'
  && !!ROOT.webkit.messageHandlers
  && !!ROOT.webkit.messageHandlers.webViewApp
  && !!ROOT.webkit.messageHandlers.webViewApp.postMessage
  && typeof ROOT.webkit.messageHandlers.webViewApp.postMessage === 'function';
// IosPostMessage = IsNewIosApp ? ROOT.webkit.messageHandlers.webViewApp.postMessage : null,
export const IsKEWLApp = (IsAndroid
    && typeof ROOT.android === 'object'
    && Object.prototype.hasOwnProperty.call(ROOT.android, 'openBoZhuPage'))
  || !!IsNewIosApp
  || reg.test(UA);
// || /live\.me-ios/i.test(UA);
export const IsAndroidApp = IsKEWLApp && !IsIOS;
