// userInfo 单例类
export default class UserInfoSingleton {
  constructor() {
    this.userInfo = null;
    this.fetchLock = false;
    this.callbacks = [];
    this.instance = null;
  }

  static getInstance() {
    console.log('~~~~~~~~~~getInstance~~~~~~~~~~');
    if (!this.instance) {
      this.instance = new UserInfoSingleton();
      console.log('new UserInfoSingleton');
    }
    return this.instance;
  }

  // 避免各个业务callback里修改userinfo数据
  // eslint-disable-next-line class-methods-use-this
  toSafe(data) {
    return typeof data === 'string' && data !== 'null' && data !== '' ? JSON.parse(data) : JSON.parse(JSON.stringify(data));
  }

  getUserInfo(callback) {
    console.log('getUserInfo');
    if (this.userInfo) {
      console.log('callback');
      callback && callback(this.toSafe(this.userInfo));
    } else {
      console.log('push callback');
      this.callbacks.push(callback);
      if (!this.fetchLock) {
        console.log('fetch lock');
        this.fetchLock = true;
        this.getNativeUserInfo();
      }
    }
  }

  getNativeUserInfo() {
    console.log('getNativeUserInfo');
    const { IsKEWLApp, IsIOS, IsNewIosApp } = window.KEWLApp.UAInfo;
    const KEWLObj = IsKEWLApp && typeof window.android === 'object' ? window.android : null;
    if (
      IsKEWLApp
      && !IsIOS
      && typeof KEWLObj === 'object'
      && Object.prototype.hasOwnProperty.call(KEWLObj, 'getUserInfo')
    ) {
      this.updateUserInfo(KEWLObj.getUserInfo(''));
    } else if (IsKEWLApp && IsIOS) {
      const fnName = window.KEWLApp.asyncCallbackName(this.updateUserInfo, this);
      console.log('fnName', fnName);
      if (IsNewIosApp) {
        window.KEWLApp.iosPostMessage({
          type: 'getuserinfo',
          callback: fnName
        });
      } else {
        window.KEWLApp.tryOpen(`getuserinfo?callback=${fnName}`);
      }
    }
  }

  updateUserInfo(obj) {
    console.log('updateUserInfo', obj);
    this.userInfo = this.toSafe(obj);
    let callback = this.callbacks.shift();
    while (callback) {
      callback && callback(this.toSafe(obj));
      callback = this.callbacks.shift();
    }
  }
}
