/**
 * @desc 多包的域名信息
 */

/**
 * @desc 默认使用liveme 主包前缀 不同包判断
 */
import updateLocation2 from '../location2';

const ROOT = window;
// const DOC = ROOT.document;
const UA = ROOT.navigator.userAgent;
const isHostName = ROOT.location2.hostname;
const chasex = 'qa.chasex.cc,www.chasex.cc'; // chasex包域名
const sparkher = 'qa.sparkher.cc,www.sparkher.cc'; // sparkher包域名
const joyu = 'qa.joyu.live,www.joyu.live'; // joyu包域名
const arablive = 'qa.royallive.com,www.royallive.com,qa.lmlite.net,www.lmlite.net,www.poplive.live,pop.lekerberos.com,royal.lekerberos.com'; // 中东域名
const aaalive = 'qa.aaalive.com,www.aaalive.com,www.frill.live,www.frilllive.com'; // 美包域名
const livemeet = 'qa.livemeet.tv,www.livemeet.tv'; // 美国包域名
const emolm = 'qa.emolm.com,www.emolm.com,www.lmpluss.com'; // emolm(liveme+)包域名
const highlive = 'qa.joilive.net,www.joilive.net,qa.highlives.net,www.highlives.net,www.hotslives.com'; // highlive包域名
const cheezlive = 'qa.cheez.com,www.cheez.com'; // cheez包域名
const meetlive = 'qa-meet.linkv.sg,meet.linkv.sg'; // meet包域名
const prolive = 'qapro.liveme.com,pro.liveme.com,pro.lekerberos.com,pro.oedipus123.com,pro.muses123.com'; // pro包域名
const arabLive = UA.indexOf('app/arab'); // 是否中东包
const usLive = UA.indexOf('app/us'); // 是否美国包
const cheezLive = UA.indexOf('app/cheez'); // 是否cheez包
const lenovoLive = UA.indexOf('app/lenovo'); // 是否lenovo包
const meetLive = UA.indexOf('app/meet'); // 是否meet包
const proLive = UA.indexOf('app/pro'); // 是否pro包
const athenaLive = UA.indexOf('app/athena'); // 是否athena包
const liveMeet = UA.indexOf('live.meet-iOS'); // 是否livemeet包
const emoLm = UA.indexOf('app/plusme'); // 是否live+包
const highLive = UA.indexOf('live.highlive-iOS'); // 是否highlive包
const chaseX = UA.indexOf('app/chasex'); // 是否chaseX包
const sparkHer = UA.indexOf('app/sparkher'); // 是否sparkHer包
const joyU = UA.indexOf('app/joyu'); // 是否joyu包

// scheme协议相关
let packageName = atob('Y21saXZl');
let packageArea = atob('Y29tLmNtY20ubGl2ZQ==');
// 包标识（在项目获取包信息，在针对接口多包标识alias字段，多包接口域名区分有重要作用） 默认值 liveme
let packageInfo = atob('bGl2ZW1l');
// kwl 需要根据 ios ua关键字 判断客户端版本 默认值为 live.me-iOS
let iosUAKeyword = atob('bGl2ZS5tZS1pT1M=');
// 中东包
if (arabLive > -1 || arablive.indexOf(isHostName) > -1) {
  packageName = 'abslive';
  packageArea = 'com.alive.android';
  packageInfo = 'arab';
}
// 美包
if (usLive > -1 || aaalive.indexOf(isHostName) > -1) {
  packageName = 'alive';
  packageArea = 'com.aaalive.live';
  packageInfo = 'us';
}
// 原美国包 liveMeet 变更为 highlive -ios 版本 【www.livemeet.tv 为子域名】
if (liveMeet > -1 || livemeet.indexOf(isHostName) > -1) {
  packageName = 'livemeet';
  packageArea = 'com.livemeet.tv';
  packageInfo = 'livemeet';
  iosUAKeyword = 'live.meet-iOS';
}
// emolm(liveme+)包域名
if (emoLm > -1 || emolm.indexOf(isHostName) > -1) {
  packageName = 'plusme';
  packageArea = 'com.plusme.live';
  packageInfo = 'emolm';
  iosUAKeyword = 'live.plusme-iOS';
}
// highlive包
if (highLive > -1 || highlive.indexOf(isHostName) > -1) {
  packageName = 'highlive';
  packageArea = 'com.highlive.live';
  packageInfo = 'highlive';
  iosUAKeyword = 'live.highlive-iOS';
}
// chasex包
if (chaseX > -1 || chasex.indexOf(isHostName) > -1) {
  packageName = 'highlive';
  packageArea = 'com.chasex.android';
  packageInfo = 'highlive';
}
// sparkher 包
if (sparkHer > -1 || sparkher.indexOf(isHostName) > -1) {
  packageName = 'joyu';
  packageArea = 'com.joyu.live';
  packageInfo = 'joyu';
}

// joyU 包
if (joyU > -1 || joyu.indexOf(isHostName) > -1) {
  packageName = 'joyu';
  packageArea = 'com.joyu.live';
  packageInfo = 'joyu';
}

// cheez包
if (cheezLive > -1 || cheezlive.indexOf(isHostName) > -1) {
  packageName = 'cheezlive';
  packageArea = 'com.joyinme.cheezlive';
  packageInfo = 'cheez';
}
// lenovo包
if (lenovoLive > -1) {
  packageInfo = 'lenovo';
}
// meet包
if (meetLive > -1 || meetlive.indexOf(isHostName) > -1) {
  packageInfo = 'meet';
}
// pro包
if (proLive > -1 || prolive.indexOf(isHostName) > -1) {
  packageName = 'cmlivepro';
  packageArea = 'com.europe.live';
  packageInfo = 'pro';
}
// athena包
if (athenaLive > -1) {
  packageName = 'lmathena';
  packageArea = 'com.athena.live';
  packageInfo = 'athena';
}

export default {
  packageName,
  packageArea,
  packageInfo,
  iosUAKeyword
};
